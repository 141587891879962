<script>
  import {tick} from "svelte";
  import {DateRangeDetail, DateDetail} from "./types";
  import {ButtonSet, Button} from "carbon-components-svelte";
  import Calendar from "./Calendar.svelte";

  const shortcuts = [
    {
      label: "Today", pick() {
        const d = new Date();
        return {
          start: {day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear()},
          end: {day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear()}
        }
      }
    },
    {
      label: "Yesterday", pick() {
        const d = new Date();
        return {
          start: {day: d.getDate() - 1, month: d.getMonth() + 1, year: d.getFullYear()},
          end: {day: d.getDate() - 1, month: d.getMonth() + 1, year: d.getFullYear()}
        }
      }
    },
    {
      label: "This Week", pick() {
        const s = new Date();
        s.setDate(s.getDate() + (s.getDay() === 0 ? -6 : 1 - s.getDay()));
        const e = new Date(s);
        e.setDate(e.getDate() + 6);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last Week", pick() {
        const s = new Date();
        s.setDate(s.getDate() + (s.getDay() === 0 ? -13 : -6 - s.getDay()));
        const e = new Date(s);
        e.setDate(e.getDate() + 6);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last 7 Days", pick() {
        const e = new Date();
        e.setDate(e.getDate() - 1);
        const s = new Date(e);
        s.setDate(s.getDate() - 6);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "This Month", pick() {
        const s = new Date();
        s.setDate(1);
        const e = new Date(s);
        e.setMonth(e.getMonth() + 1);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last Month", pick() {
        const s = new Date();
        s.setDate(1);
        s.setMonth(s.getMonth() - 1);
        const e = new Date(s);
        e.setMonth(e.getMonth() + 1);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last 30 Days", pick() {
        const e = new Date();
        e.setDate(e.getDate() - 1);
        const s = new Date(e);
        s.setDate(s.getDate() - 29);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "This Quarter", pick() {
        const s = new Date();
        s.setDate(1);
        s.setMonth(s.getMonth() - (s.getMonth() % 3));
        const e = new Date(s);
        e.setMonth(e.getMonth() + 3);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last Quarter", pick() {
        const s = new Date();
        s.setDate(1);
        s.setMonth(s.getMonth() - (s.getMonth() % 3) - 3);
        const e = new Date(s);
        e.setMonth(e.getMonth() + 3);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last 90 Days", pick() {
        const e = new Date();
        e.setDate(e.getDate() - 1);
        const s = new Date(e);
        s.setDate(s.getDate() - 89);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "This Year", pick() {
        const s = new Date();
        s.setDate(1);
        s.setMonth(0);
        const e = new Date(s);
        e.setFullYear(e.getFullYear() + 1);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
    {
      label: "Last Year", pick() {
        const s = new Date();
        s.setDate(1);
        s.setMonth(0);
        s.setFullYear(s.getFullYear() - 1);
        const e = new Date(s);
        e.setFullYear(e.getFullYear() + 1);
        e.setDate(e.getDate() - 1);
        return {
          start: {day: s.getDate(), month: s.getMonth() + 1, year: s.getFullYear()},
          end: {day: e.getDate(), month: e.getMonth() + 1, year: e.getFullYear()}
        }
      }
    },
  ];

  function getMonths() {
    if (value) {
      const calendar1 = {month: value.start.month, year: value.start.year};
      let calendar2 = {month: value.end.month, year: value.end.year};
      if (calendar1.month === calendar2.month && calendar1.year === calendar2.year) {
        calendar2 = calendar2.month === 12 ? {month: 1, year: calendar2.year + 1} : {
          month: calendar2.month + 1,
          year: calendar2.year
        };
      }
      return {calendar1, calendar2};
    } else {
      const now = new Date();
      const calendar1 = {month: now.getMonth() + 1, year: now.getFullYear()};
      const calendar2 = calendar1.month === 12 ? {month: 1, year: calendar1.year + 1} : {
        month: calendar1.month + 1,
        year: calendar1.year
      };
      return {calendar1, calendar2};
    }
  }

  export let value = null;

  let selecting = null;
  let months = getMonths();

  $: calendar2Min = months.calendar1.month === 12 ? {
    month: 1,
    year: months.calendar1.year + 1
  } : {month: months.calendar1.month + 1, year: months.calendar1.year};

  function handleDatePicked(date) {
    if (selecting) {
      value = new DateRangeDetail(selecting.start, date);
      selecting = null;
    } else {
      selecting = new DateRangeDetail(date, date);
    }
  }

  function handleDateHovered(date) {
    if (selecting) {
      selecting = {start: selecting.start, end: date};
    }
  }

  function handleShortcut(shortcut) {
    const {start, end} = shortcut.pick();
    value = new DateRangeDetail(new DateDetail(start.day, start.month, start.year), new DateDetail(end.day, end.month, end.year));
  }

  async function updateMonths() {
    await tick();
    months = getMonths();
  }

  $: updateMonths(value);
</script>

<div class="date-range">
    <div class="shortcuts">
        <ButtonSet stacked>
            {#each shortcuts as shortcut}
                <Button size="small" kind="secondary"
                        on:click={() => handleShortcut(shortcut)}>{shortcut.label}</Button>
            {/each}
        </ButtonSet>
    </div>
    <Calendar bind:month={months.calendar1} selected={selecting || value} highlight={selecting !== null}
              on:datePicked={({detail}) => handleDatePicked(detail)}
              on:dateHovered={({detail}) => handleDateHovered(detail)}/>
    <div class="shortcuts spacer"/>
    <Calendar bind:month={months.calendar2} min={calendar2Min} selected={selecting || value}
              highlight={selecting !== null}
              on:datePicked={({detail}) => handleDatePicked(detail)}
              on:dateHovered={({detail}) => handleDateHovered(detail)}/>
</div>

<style>
    .date-range {
        display: inline-grid;
        grid-template-columns: auto 224px 224px;
        gap: 1rem;
        padding: 1rem;
    }
    .shortcuts {
        margin-top: 1px;
        height: 255px;
        overflow: scroll;
    }
    .shortcuts.spacer {
        display:none;
    }

    @media (max-width: 768px) {
        .date-range {
            grid-template-columns: auto 224px;
        }
        .shortcuts.spacer {
            display: block;
        }
    }

    @media (max-width: 468px) {
        .date-range {
            grid-template-columns: 100%;
            width:100%;
        }
        .shortcuts.spacer {
            display: none;
        }
        :global(.date-range-picker .bx--popover-contents) {
            max-width: none;
            width:100%;
        }
        .shortcuts :global(.bx--btn-set button) {
            width:100%;
            max-width: 100%;
            padding-right:12px;
        }
        .date-range :global(.calendar) {
            margin: 0 auto;
        }
    }

</style>