<script>
  import {ListBox, ListBoxMenuIcon, Popover} from "carbon-components-svelte";
  import DateRange from "./DateRange.svelte";

  export let value = null;
  export let titleText = null;
  export let disabled = false;

  let open = false;

  function toggle() {
    if (open) {
      open = false;
    } else if (!disabled) {
      open = true;
    }
  }

  function close() {
    open = false;
  }

  $: close(value);
  $: valueText = disabled ? "None": value ? value.start.equals(value.end) ? value.start.toPrettyString() : `${value.start.toPrettyString()} - ${value.end.toPrettyString()}` : "";
</script>

<div class="date-range-picker">
    {#if titleText}
        <span class:bx--label="{true}">{titleText}</span>
    {/if}
    <ListBox class="bx--dropdown {open && 'bx--dropdown--open'}" {open} {disabled}>
        <button class="bx--list-box__field" tabindex="0" aria-expanded={open}
                on:click={() => toggle()}>
            <span class="bx--list-box__label">{valueText}</span>
            <ListBoxMenuIcon open={open}/>
        </button>
    </ListBox>
    <Popover {open} caret align="top-left">
        <DateRange bind:value={value}/>
    </Popover>
</div>

<style>
    .date-range-picker {
        position: relative;
        width:100%;
    }
    .date-range-picker :global(.bx--popover-contents) {
        max-width: none;
    }
    :global(.date-range-picker .bx--popover) {
        width: 100%;
    }
</style>