<script>
  import { Loading } from "carbon-components-svelte";
</script>
<div>
    <Loading withOverlay={false} />
    <h1>Please Wait</h1>
</div>
<style>
    div {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin: 3rem 0 0 0;
    }
</style>