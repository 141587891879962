<script>
  import router from "page";
  import {Button} from "carbon-components-svelte";
  import auth from "./auth";
  import Dashboard from "./Dashboard.svelte";
  import Parameters from "./components/Parameters.svelte";
  import {UserAvatar} from "carbon-icons-svelte";

  // routing
  let page, params;
  router("/", () => (page = Dashboard));
  router.start();

  const headerActions = [
    {
      description: "Sign Out", icon: UserAvatar, action: auth.logout
    }
  ];
</script>

<div class="app">
    <header>
        <div class="navigation">
            <div class="brand">
                <img src="/logo.png" alt="Believe" />
                <span>Introducer Portal</span>
            </div>
            <div class="actions">
                <Parameters />
            </div>
            <div class="signout">
                {#each headerActions as item}
                    <Button kind="ghost" iconDescription={item.description} icon={item.icon} tooltipPosition="left"
                            tooltipAlignment="start" on:click={item.action}/>
                {/each}
            </div>
        </div>
        <div class="mobile">
            <Parameters/>
        </div>
    </header>
    <main>
        <svelte:component this={page} params={params}/>
    </main>
</div>

<style>
    .app {
        min-height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
    }

    header {
        padding: 2rem;
        border-bottom: 1px solid var(--cds-ui-04);
    }

    header .navigation {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:2rem;
    }

    header .navigation .brand {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
    }

    header .navigation .brand span {
        white-space: nowrap;
    }

    header .navigation .brand img {
        height: 3.5rem;
        margin-right: 1rem;
        background-color: white;
        padding: 0.5rem;
    }

    header .navigation .actions {
        display: flex;
        align-items: flex-end;
        width:100%;
    }

    header .mobile {
        display: none;
    }

    main {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        background-color: var(--cds-ui-02);
        position: relative;
    }

    @media (max-width: 1500px) {
        header .navigation .actions {
            display:none;
        }
        header .mobile {
            display: flex;
            align-items: flex-end;
            margin-top:1rem;
        }
    }

    @media (max-width: 450px) {
        header .navigation .brand img {
            display:none;
        }
    }

</style>