<script>
  import {parameters, user, campaigns} from "../store";
  import {
    Modal,
    DataTable,
    Pagination,
    DataTableSkeleton
  } from "carbon-components-svelte";

  const productMap = {firstCharge: "first", secondCharge: "second", specialist: "specialist"};

  const sameIntroducer = new Set($campaigns[$parameters.product].map(c => c.introducer)).size === 1;

  let open = false;
  let key, label, title, data, page, total, rows;

  $: serverPage = Math.ceil(page / 5);
  $: if (data) {
    const start = ((page - 1) % 5) * 10;
    rows = data.slice(start, start + 10);
  }

  function formatDate(d) {
    if (!d) return "";
    return `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)} ${d.slice(11, 16)}`;
  }

  function formatCurrency(n) {
    if (typeof n !== "number") return "£0";
    const pounds = Math.floor(n);
    const pence = Math.round((n * 100) % 100);
    let result = `£${pounds.toLocaleString()}`;
    if (pence > 0) {
      return `${result}.${pence < 10 ? "0" : ""}${pence}`
    }
    return result;
  }

  function formatLoanTerm(n) {
    if (typeof n !== "number") return "";
    return n === 1 ? "1 month" : `${n} months`
  }

  function formatPercentage(n) {
    if (typeof n !== "number") return "";
    return `${n}%`;
  }

  async function update() {
    data = null;
    let url = `/api/${productMap[$parameters.product]}/detail/${key}/${$parameters.view}/${$parameters.dateRange.start.toString()}/${$parameters.dateRange.end.toString()}`;
    if ($parameters.campaign) {
      url = `${url}/${$parameters.campaign}`;
    }
    url = `${url}?page=${serverPage}`;
    const results = await fetch(url, {headers: {Authorization: `Bearer ${$user.token}`}}).then(res => res.json());
    data = results.rows.map(r => ({
      id: r.reference,
      introducerReference: r.introducerReference || "",
      campaign: sameIntroducer ? r.mediaName : `${r.introducerName} - ${r.mediaName}`,
      productType: r.productType,
      status: r.status,
      subStatus: r.subStatus || "",
      loanAmount: formatCurrency(r.loanAmount),
      loanTerm: formatLoanTerm(r.loanTerm),
      received: formatDate(r.received),
      proceeding: formatDate(r.proceeding),
      documentsReceived: formatDate(r.documentsReceived),
      transferred: formatDate(r.transferred),
      paidOut: formatDate(r.paidOut),
      revenue: formatCurrency(r.revenue)
    }));
    total = results.total.total;
  }

  export function show(pKey, pTitle) {
    let campaignText = "all campaigns";
    if ($parameters.campaign !== null) {
      const campaign = $campaigns[$parameters.product].find(c => c.id === $parameters.campaign);
      campaignText = sameIntroducer ? campaign.name : `${campaign.introducer} - ${campaign.name}`;
    }
    if ($parameters.view === "pipeline") {
      label = `Pipeline (${campaignText})`;
    } else {
      label = `${$parameters.view === "waterfall" ? "Leads" : pTitle} from ${$parameters.dateRange.toString()} from ${campaignText}`;
    }
    title = pTitle;
    key = pKey;
    page = 1;
    open = true;
  }

  $: if (open && serverPage) {
    update();
  }

  async function download() {
    let url = `/api/${productMap[$parameters.product]}/detail/${key}/${$parameters.view}/${$parameters.dateRange.start.toString()}/${$parameters.dateRange.end.toString()}`;
    if ($parameters.campaign) {
      url = `${url}/${$parameters.campaign}`;
    }
    url = `${url}?format=csv`;
    const blob = await fetch(url, {headers: {Authorization: `Bearer ${$user.token}`}}).then(res => res.blob());
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, "report.csv");
    } else {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = "report.csv";
      a.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
</script>
<div class="detail">
    <Modal bind:open size="lg" modalLabel={label} modalHeading={title} primaryButtonText="Download"
           secondaryButtonText="Close" on:click:button--secondary={() => open = false} on:submit={() => download()}>
        {#if data === null}
            <DataTableSkeleton rows={11} showToolbar={false} showHeader={false}/>
        {:else}
            <div class="table">
                <DataTable
                        headers={[{key: "id", value: "Reference"}, {key:"introducerReference", value: "Introducer Reference"}, {key:"campaign", value:"Campaign"}, {key: "productType", value: "Product Type"}, {key:"status", value: "Status"}, {key: "subStatus", value: "Sub Status"},{key:"loanAmount", value: "Loan Amount"}, {key:"loanTerm", value: "Loan Term"},{key:"received", value:"Lead Received"},{key:"transferred", value:"Lead Transferred"}, {key:"proceeding", value:"Pack Out"}, {key:"documentsReceived", value:"Pack In"}, {key:"paidOut", value:"Paid Out"}, {key:"revenue", value:"Revenue"}]}
                        rows={rows}/>
            </div>
            <Pagination totalItems={total} bind:page={page} pageSize={10} pageSizeInputDisabled={true}
                        pageInputDisabled={true}/>
        {/if}
    </Modal>
</div>

<style>
    .detail :global(.bx--modal-content) {
        padding-right: var(--cds-spacing-05, 1rem);
    }

    .table {
        height: calc(33.15625rem - 1px);
        overflow: auto;
    }

    .detail .table :global(.bx--data-table th), .detail .table :global(.bx--data-table td) {
        white-space: nowrap;
    }
</style>
