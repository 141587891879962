import {DateRangeDetail} from "./components/DateRange/types";

export class Parameters {
  constructor() {
    this.view = "waterfall";
    this.dateRange = DateRangeDetail.today();
    this.product = null;
    this.campaign = null;
  }
  clone() {
    const p = new Parameters();
    p.view = this.view;
    p.dateRange = this.dateRange;
    p.product = this.product;
    p.campaign = this.campaign;
    return p;
  }
  equals(other) {
    return other.view === this.view && other.dateRange.equals(this.dateRange) && other.product === this.product && other.campaign === this.campaign;
  }
}
