import {writable, get} from "svelte/store";
import {Parameters} from "./types";

export const user = writable({
  isAuthenticated: false
});

export const parameters = writable(new Parameters());

export const campaigns = writable({firstCharge: [], secondCharge: [], specialist: []});
