<script>
    import {onMount} from "svelte";
    import {user} from "./store";
    import {LookerEmbedSDK} from "@looker/embed-sdk";
    import {Loading} from "carbon-components-svelte";

    export let params;
    $: lookerDashboard = params.campaign;

    let init = false;
    let loading = true;

    // if lookerDashboard changes set loading
    $: {
        loading = true;
        loadDashboard(lookerDashboard);
    }

    onMount(async () => {
        //console.log("Looker View mounted");
        LookerEmbedSDK.initCookieless(
            "c2bmoneygroup.cloud.looker.com",
            {
                headers: {
                    Authorization: `Bearer ${$user.token}`
                },
                url: "/api/looker/acquire-embed-session"
            },
            {
                headers: {
                    Authorization: `Bearer ${$user.token}`
                },
                url: "/api/looker/generate-embed-tokens"
            }
        );
        init = true;
        loadDashboard(lookerDashboard);
    });

    function loadDashboard(dash) {
        if (!init) return;
        //console.log(`Rendering looker dashboard ${dash}`)
        // clear contents of looker div
        document.getElementById("looker").innerHTML = "";
        // create looker dashboard
        LookerEmbedSDK.createDashboardWithId(dash)
            .appendTo(document.getElementById("looker"))
            .on('dashboard:run:complete', () => {
                //console.log("Dashboard Run Complete");
                loading = false;
            })
            .withNext()
            .build()
            .connect();
    }

</script>

<div>
    {#if loading}
        <div class="loading">
            <Loading withOverlay={false}/>
            <h1>Loading dashboard...</h1>
        </div>
    {/if}
    <div id="looker" class="looker-dashboard {loading ? 'loading' : ''}"></div>
</div>

<style>
    :global(.looker-dashboard),
    :global(.looker-dashboard iframe) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    :global(.looker-dashboard.loading) {
        display: none !important;
    }
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }
    .loading h1 {
        margin-top: 2rem;
    }
</style>
