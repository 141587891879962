export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export class DateDetail {
  constructor(day, month, year) {
    this.day = day;
    this.month = month;
    this.year = year;
  }

  equals(other) {
    return other.day === this.day && other.month === this.month && other.year === this.year;
  }

  static today() {
    const now = new Date();
    return new DateDetail(now.getDate(), now.getMonth() + 1, now.getFullYear());
  }

  toString() {
    return `${this.year}-${this.month < 10 ? "0" : ""}${this.month}-${this.day < 10 ? "0" : ""}${this.day}`
  }

  toPrettyString() {
    const suffix = this.day === 1 || this.day === 21 || this.day === 31 ? "st" : this.day === 2 || this.day === 22 ? "nd" : this.day === 3 || this.day === 23 ? "rd" : "th";
    return `${this.day}${suffix} ${monthNames[this.month - 1]} ${this.year}`;
  }
}

export class DateRangeDetail {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  equals(other) {
    return other.start.equals(this.start) && other.end.equals(this.end);
  }

  static today() {
    const d = DateDetail.today();
    return new DateRangeDetail(d, d);
  }

  toString() {
    return this.start.equals(this.end) ? this.start.toPrettyString() : `${this.start.toPrettyString()} - ${this.end.toPrettyString()}`;
  }
}
