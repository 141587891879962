<script>
  import {onMount} from "svelte";
  import auth from "./auth";
  import {campaigns, user} from "./store";
  import Bootstrap from "./Bootstrap.svelte";
  import Root from "./Root.svelte";

  let ready = false;
  onMount(async () => {
    await auth.checkSession();
    const fetchCampaigns = async (role) => {
        return fetch(`/api/${role}/campaigns`, {
            headers: {
                Authorization: `Bearer ${$user.token}`
            }
        })
            .then(res => res.json())
            .catch(() => Promise.resolve([]));
    };
    const [firstCharge, secondCharge, specialist, dashboards] = await Promise.all([
        fetchCampaigns("first"),
        fetchCampaigns("second"),
        fetchCampaigns("specialist"),
        fetchCampaigns("looker")
    ]);
    campaigns.set({ firstCharge, secondCharge, specialist, dashboards });
    ready = true;
  });
</script>

{#if ready}
    <Root/>
{:else}
    <Bootstrap/>
{/if}

<style>
    :global(html, body) {
        min-width: 320px;
    }
</style>
