<script>
  import {createEventDispatcher} from "svelte";
  import {DateDetail, monthNames} from "./types";
  import {ChevronLeft, ChevronRight} from "carbon-icons-svelte";


  const dispatch = createEventDispatcher();

  function defaultMonth() {
    const now = new Date();
    return {month: now.getMonth() + 1, year: now.getFullYear()};
  }

  const dates = new Array(42);

  export let month = defaultMonth();
  export let selected = null;
  export let highlight = false;
  export let min = null;

  $: prev = month.month === 1 ? {month: 12, year: month.year - 1} : {
    month: month.month - 1,
    year: month.year
  };

  $: next = month.month === 12 ? {month: 1, year: month.year + 1} : {
    month: month.month + 1,
    year: month.year
  };

  $: canPrevious = !min || min.year < month.year || (min.year === month.year && min.month < month.month);

  $: if (min && (month.year < min.year || (month.year === min.year && month.month < min.month))) {
    month = min;
  }

  $: {
    const date = new Date(month.year, month.month - 1, 1);
    const selectionStart = selected ? new Date(selected.start.year, selected.start.month - 1, selected.start.day) : null;
    const selectionEnd = selected ? new Date(selected.end.year, selected.end.month - 1, selected.end.day) : null;
    let daysDifference = 1 - date.getDay();
    if (daysDifference > 0) daysDifference -= 7;
    date.setDate(date.getDate() + daysDifference);
    for (let i = 0; i < 42; i++) {
      dates[i] = {
        day: date.getDate(),
        disabled: date.getMonth() !== month.month - 1,
        selected: selected && date >= selectionStart && date <= selectionEnd,
        highlighted: selected && highlight && date > selectionStart && date <= selectionEnd
      };
      date.setDate(date.getDate() + 1);
    }
  }

  function previousYear() {
    month = {month: month.month, year: month.year - 1};
  }

  function previousMonth() {
    month = prev;
  }

  function nextMonth() {
    month = next;
  }

  function nextYear() {
    month = {month: month.month, year: month.year + 1};
  }

  function pickDate({day}) {
    dispatch("datePicked", new DateDetail(day, month.month, month.year));
  }

  function hoverDate({day, disabled}) {
    if (!disabled) {
      dispatch("dateHovered", new DateDetail(day, month.month, month.year));
    }
  }
</script>

<div class="calendar">
    <button disabled={!canPrevious} on:click={() => previousYear()}>
        <ChevronLeft/>
    </button>
    <button disabled={!canPrevious} on:click={() => previousMonth()}>
        <ChevronLeft/>
    </button>
    <span class="month-display">{monthNames[month.month - 1]} {month.year}</span>
    <button on:click={() => nextMonth()}>
        <ChevronRight/>
    </button>
    <button on:click={() => nextYear()}>
        <ChevronRight/>
    </button>
    <span class="day-header">M</span>
    <span class="day-header">T</span>
    <span class="day-header">W</span>
    <span class="day-header">T</span>
    <span class="day-header">F</span>
    <span class="day-header">S</span>
    <span class="day-header">S</span>
    {#each dates as date}
        <button disabled={date.disabled} class:selected={!date.disabled && date.selected}
                class:highlighted={!date.disabled && date.highlighted}
                on:click={() => pickDate(date)} on:mouseenter={() => hoverDate(date)}>{date.day}</button>
    {/each}
</div>

<style>
    .calendar {
        display: grid;
        grid-template-rows: repeat(8, 32px);
        grid-template-columns: repeat(7, 32px);
        align-items: center;
        justify-content: center;
        width: 224px;
    }

    button {
        display: block;
        height: 32px;
        border: none;
        outline: none;
        background: none;
        padding: 0;
        cursor: pointer;
        color: var(--cds-interactive-03);
        font-size: 0.8rem;
    }

    button:active {
        background: none;
    }

    button[disabled] {
        cursor: default;
        color: var(--cds-disabled-03);
    }

    button.selected {
        background-color: var(--cds-interactive-01);
    }

    button.selected.highlighted {
        background-color: var(--cds-interactive-04);
    }

    .month-display {
        font-weight: bold;
        text-align: center;
        transform: translateY(-2px);
        grid-column: 3 / 6;
    }

    .day-header {
        font-weight: bold;
        text-align: center;
    }
</style>